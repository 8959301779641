
html, body {
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  background-color: white;
}

header {
  background-color: #19d8e6;
  height: 2vw;
}

h2 {
  color: #19d8e6;
  font-family: 'Actor', sans-serif;
  font-size: 2.30vw;
  padding-left: 10%;
  text-align: left;
}



h3 {
  font-family: 'Actor', sans-serif;
  color: #686868;
  font-size: 1.7vw;
  padding: .2vw;
  margin: 0;
}



.results {
  vertical-align: middle;
  text-align: left;
  width: 100%;
  padding-left: 10%;
  padding-top: 10%;
}


.inputText {
  border: 3px solid #dedede;
  border-radius: 5px;
  width: 80%;
  height: 2.9vw;
  font-family: 'Actor', sans-serif;
  color: #686868;
  font-size: 1.7vw;
  padding-left: .5vw;
}




input[type=text]:focus {
  border: 3px solid #686868;
  outline: none;
}

.inputSubmit {
  border: 3px solid #686868;
  border-radius: 5px;
  width: 5vw;
  height: 3.3vw;
  font-family: 'Actor', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 1.7vw;
  background-color:  #686868;
  margin-left: .8vw;
   -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  vertical-align: center;
  margin-top: 2px; 
   box-sizing: border-box;

}

input[type=submit]:disabled {
  background-color:  #dedede; 
  border: 3px solid #dedede; 
}

.busGif {
  height: 25vw;
  pointer-events: none;
}

.App {
  text-align: center;
  width: 100%;
  background-color: white;
  padding-bottom: 100%;
  height: 100%;
}


.mobile_form {
  width: 100%;
}

.mobile_inputText {
  border: 3px solid #686868;
  border-radius: 5px 5px 5px 5px ;
  font-family: 'Actor', sans-serif;
  color: #686868;
  text-align: left;
  padding: 10px;
  font-size: 17px;
  float: left;
  width: 83%;
  vertical-align: center;
  box-sizing: border-box;
  margin-left: 5px;
}

.mobile_inputSubmit {
  
  border-left:  3px solid #686868;
  border-radius: 5px 5px 5px 5px ;
  width: 14%;
  font-family: 'Actor', sans-serif;
  font-size: 17px;
  font-weight: bold;
  height: 45px;
  color: white;
  text-align: left;
  background-color:  #686868;
  float: right;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-appearance: none;
  vertical-align: center;
  margin-right: 5px;
  margin-top: 2px;
}

.mobile_inputSubmit:disabled {
  background-color:  #686868; 
  border: 0px 5px 5px 0px solid #dedede; 

}

form.mobile_form::after {
  content: "";
  clear: both;
  display: table;
}


.mobile_busGif {
  margin-top: 3px;
  width: 100%;
  pointer-events: none;
}

.mobile_results {
  vertical-align: middle;
  text-align: center;

}

.mobile_h3 {
  font-family: 'Actor', sans-serif;
  color: #686868;
  font-size: 5vw;
  margin: 0;
  text-align: center;
}

.mobile_h2 {
  color: #19d8e6;
  font-family: 'Actor', sans-serif;
  font-size: 18px;
  text-align: left;
  padding-left: 00%;
  margin-left: 10px;
}

.StruggleBusMain {
  padding: 0px;
}

.mobile_background {
}


