

.startOver {
	cursor: pointer;
	color: #ffffff;
	padding-top: 0px;
}

.startOver:hover {
	cursor: pointer;
	color: #fff141;
	padding-top: 0px;
}

footer {
	text-align: right;
	bottom:0;
	position: absolute;
	right: 0;
	padding-right: 10px;
}

.tshirt{
	cursor: pointer;
	color: #ffffff;
	font-family: 'Actor', sans-serif;
	font-size:  13px;
}

.mobile_tshirt{
	cursor: pointer;
	color: #ffffff;
	font-family: 'Actor', sans-serif;
	font-size:  16px;
}

.tshirt:hover{
	cursor: pointer;
	color: #fff141;
}

.mobile_ashes {
  padding-top: 110px;
  width: 40%;
  margin: 10px auto 20px;
  display: block;
}
.mobile_ashes_un {
  margin-top: 3px;
  width: 100%;
  pointer-events: none;
  display: inline-block;
}

.ashes {
  padding-top: 10px;
  height: 10vw;
  pointer-events: none;
}
