html, body {
	width: 100%;
	height: 100%;
    margin: auto;
    overflow: hidden;
    background-color: #19d8e6;
}


h1 {
	padding-top: 8%;
	color: #ffffff;
	font-family: 'Actor', sans-serif;
	font-size: 5.75vw;
	padding-left: 10%;
	padding-right: 10%;
}



.bottom:hover {
	cursor: pointer;
	color: #fff141;
}
